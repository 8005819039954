<template>
  <RtbCard class="meeting-announcement-card">
    <v-layout column justify-center>
      <v-flex class="text-md-center" v-if="announcement" v-html="announcement">
      </v-flex>
    </v-layout>
  </RtbCard>
</template>

<script>
import { mapGetters } from "vuex"
const RtbCard = () => import("@/components/GroupTeams/Common/Games/RtbCard.vue")

export default {
  name: "MeetingAnnouncement",
  components: {
    RtbCard
  },
  computed: {
    ...mapGetters(["game", "getCurrentMission"]),
    announcement() {
      if (this.game.meetingAnnouncement.length > 1) {
        return this.game.meetingAnnouncement
      } else if (this.getCurrentMission.meetingText) {
        return this.getCurrentMission.meetingText
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss">
.meeting-announcement-card {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: fit-content;
  height: auto;
  overflow: scroll;
  max-height: 120px;
  max-width: 50%;
  min-width: 30%;
  padding: 5px !important;
  font-size: 21px;
  font-weight: bolder;
  overflow-wrap: break-word;
  overflow-y: auto !important;
  a {
    color: $primary-accent-color;
  }
}
</style>
