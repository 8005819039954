var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RtbCard",
    { staticClass: "meeting-announcement-card" },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "justify-center": "" } },
        [
          _vm.announcement
            ? _c("v-flex", {
                staticClass: "text-md-center",
                domProps: { innerHTML: _vm._s(_vm.announcement) },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }